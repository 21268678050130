import React from "react"
import PropTypes from "prop-types"


const Hero = props => {
  
  const { subtitle } = props
  
  return (
  <div className="hero container py-12 lg:pb-16">
    <h2 className="text-3xl sm:text-4xl font-extrabold leading-tight tracking-tight text-white">
{/*   
      <span className="text-pink-500">
        {title}
      </span>
      <br /> */}
      {/* Hello, I'm John{" "} */}
      {subtitle}
      {/* <span role="img" aria-label="waving hand">
        👋🍽
      </span> */}
    </h2>
  </div>
  )
}


Hero.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
}

export default Hero
